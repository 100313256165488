<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Contáctanos',

    metaInfo: {
      title: 'Contacto',
      meta: [
        { name: 'description', content: 'Contacta con nosotros. Artesanos en especialidades' },
        { name: 'keywords', content: 'Contacto,Cantdelimar' },
      ],
    },

    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
        'keep-in-touch',
        'map',
        'newsletter',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'contacto',
      },
    },
  }
</script>
